<template>
  <div class="content-page">
    <b-card class="mb-3 my-card">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
          <el-breadcrumb-item>Shipping address</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </b-card>
    <b-card>
      <div class="row mb-3">
        <div class="col">
          <h4 class="d-flex align-items-center">
            <i style="color: #50b5ff" class="ri-map-pin-line mr-3"></i>Shipping address
          </h4>
        </div>
        <div class="col text-right">
          <button class="btn" @click="openModal">+ Add New</button>
        </div>
      </div>

      <div class="row v-center" v-for="(v,i) of myAddresses" :key="i">
        <div class="col-2 d-flex align-items-center">
          <div class="name_avatar">{{v.consignee_name.substr(0, 1)}}</div>
          <h5 class="ml-3">{{v.consignee_name}}</h5>
        </div>
        <div class="col-2">{{v.phone_prefix}} {{v.consignee_phone}}</div>
        <div class="col-5">
          <h6>{{v.address}}, {{v.country}}</h6>
        </div>
        <div class="col-2 text-right">
          <router-link to=""><u @click="(val)=>{openModal(val,v)}">Edit</u></router-link>
          <router-link style="color: #ef5959" class="ml-3" to=""><u @click="(val)=>{delAddress(val,v)}">Delete</u>
          </router-link>
        </div>
        <div class="b-border my-3" style="background: #eee;"></div>
      </div>
    </b-card>

    <!-- 弹框 -->
    <b-modal id="addressModal" centered title="Edit Shipping address" hide-footer>
      <div class="add_address">
        <div class="row">
          <div class="col-6">
            <h6>Consignee</h6>
            <el-input class="int" type="text" v-model="params.consignee_name" />
          </div>
          <div class="col-6">
            <h6>Phone number</h6>
            <div class="code_phone">
              <el-input class="int" type="text" v-model="params.consignee_phone" />
              <el-select v-model="params.phone_prefix" style="width: 100%;" filterable clearable placeholder="">
                <el-option v-for="item in areaCodeList" :key="item.code_id" :label="item.code_name"
                  :value="item.code_name">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div>
          <h6 class="postcode">Postcode</h6>
          <el-input class="int w-50" type="text" v-model="params.post_code" />
        </div>
        <div class="country">
          <h6>Country</h6>
          <el-select v-model="selectData" value-key="id" style="width: 100%;" filterable clearable placeholder="">
            <el-option v-for="(item,i) in countryList" :key="i" :label="item.name" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="mb-3">
          <h6>Detailed address</h6>
          <el-input type="textarea" class="int-area" v-model="params.address" resize="none"></el-input>
        </div>
        <!-- <div class="mb-5 switch">
          <el-switch v-model="is_default" active-text="Set as default address"></el-switch>
        </div> -->
        <hr />
        <div class="text-right">
          <button class="btn" @click="handleSave">Save</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import areaCodeList from '@/assets/js/code.js'
  export default {
    data() {
      return {
        is_default: false,
        myAddresses: [],
        params: {
          consignee_name: '',
          consignee_phone: '',
          post_code: '',
          address: '',
          phone_prefix: ''
        },
        countryList: [],
        selectData: {},
        areaCodeList,
      };
    },

    created() {
      this._myAddresses()
      this._getAddress()
    },

    methods: {
      openModal(val, v) {
        this.$bvModal.show('addressModal')
        if (v) {
          Object.keys(this.params).forEach(item => {
            this.params[item] = v[item]
          })
          this.selectData.id = v.country_id
          this.selectData.name = v.country
          this.is_default = Boolean(v.is_default)
          this.id = v.id
        } else {
          Object.keys(this.params).forEach(item => {
            this.params[item] = ""
          })
          this.selectData = {}
          this.is_default = false
          this.id = ""
        }
      },

      // 收货地址列表
      async _myAddresses() {
        let res = await this.$http.myAddresses()
        if (res.status == 200) {
          this.myAddresses = res.data
        }
      },

      // 国家列表
      async _getAddress() {
        let res = await this.$http.getAddress({ level: 2 })
        if (res.status == 200) {
          this.countryList = res.data
        }
      },

      // 添加收货地址
      async handleSave() {
        if ((!this.params.consignee_name ||
          !this.params.consignee_phone ||
          !this.selectData.id ||
          !this.params.address)
        ) {
          return this.$message.warning('Please fill in the complete information !')
        }

        let res

        let data = {
          ...this.params,
          country_id: this.selectData.id,
          country: this.selectData.name,
          is_default: Number(this.is_default)
        }

        if (this.id) {
          // 修改
          res = await this.$http.editAddress({ ...data, id: this.id })
        } else {
          // 添加
          res = await this.$http.addAddress(data)
        }

        if (res.status == 200) {
          this.$bvModal.hide('addressModal')
          this.myAddresses = res.data
        }
      },

      // 删除地址
      delAddress(val, v) {
        this.$confirm("Are you sure you want to delete?", "Tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(async () => {
            let res = await this.$http.delAddress({ id: v.id })
            if (res.status == 200) {
              this._myAddresses()
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Deletion cancelled",
            });
          })
      }
    },
  };
</script>

<style lang="scss" scoped>
  .my-card {
    .card-body {
      padding: 0;
    }
  }

  .breadcrumb {
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    ::v-deep span.el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .b-border {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
  }

  .v-center {
    align-items: center;
  }

  .name_avatar {
    width: 30px;
    height: 30px;
    background: #ddd;
    border-radius: 50%;
    color: #fff;
    line-height: 30px;
    text-align: center;
  }

  .btn {
    width: 110px;
    height: 35px;
    color: #50b5ff;
    background: #f5fbff;
    border: 1px solid #50b5ff;
    border-radius: 5px;
  }


  ::v-deep .modal-dialog.modal-md.modal-dialog-centered {
    max-width: 800px !important;
  }

  .switch {
    ::v-deep span {
      color: #3F414D;
      font-weight: normal;
    }
  }

  .add_address {
    color: #3F414D;

    h6 {
      display: flex;
      position: relative;
      margin: 15px 0;

      &::after {
        display: block;
        content: '*';
        color: red;
        margin-left: 3px;
      }
    }

    .postcode::after {
      content: '';
    }

    .int {
      ::v-deep input {
        width: 100%;
        height: 45px;
        border-radius: 5px;
      }
    }

    ::v-deep textarea {
      width: 100%;
      height: 85px;
      border-radius: 5px;
    }

    ::v-deep .country {
      input {
        height: 45px !important;
      }
    }

    .code_phone {
      position: relative;

      .int {
        ::v-deep input {
          text-indent: 120px;
        }
      }

      ::v-deep .el-select {
        position: absolute !important;
        left: 0;
        width: 120px !important;
        height: 45px !important;
        padding: 2px;

        input {
          width: 120px !important;
          border: none;
        }
      }

    }
  }
</style>