let areaCodeList= [
  {
    code_id: "1",
    code_name: "+1",
  },
  {
    code_id: "1-264",
    code_name: "+1-264",
  },
  {
    code_id: "1-268",
    code_name: "+1-268",
  },
  {
    code_id: "1-242",
    code_name: "+1-242",
  },
  {
    code_id: "1-246",
    code_name: "+1-246",
  },
  {
    code_id: "1-441",
    code_name: "+1-441",
  },
  {
    code_id: "1-284",
    code_name: "+1-284",
  },
  {
    code_id: "1-345",
    code_name: "+1-345",
  },
  {
    code_id: "1-684",
    code_name: "+1-684",
  },
  {
    code_id: "1-767",
    code_name: "+1-767",
  },
  {
    code_id: "1-809",
    code_name: "+1-809",
  },
  {
    code_id: "1-473",
    code_name: "+1-473",
  },
  {
    code_id: "1-876",
    code_name: "+1-876",
  },
  {
    code_id: "1-664",
    code_name: "+1-664",
  },
  {
    code_id: "1-787",
    code_name: "+1-787",
  },
  {
    code_id: "1-939",
    code_name: "+1-939",
  },
  {
    code_id: "1-869",
    code_name: "+1-869",
  },
  {
    code_id: "1-758",
    code_name: "+1-758",
  },
  {
    code_id: "1-784",
    code_name: "+1-784",
  },
  {
    code_id: "1-868",
    code_name: "+1-868",
  },
  {
    code_id: "1-649",
    code_name: "+1-649",
  },
  {
    code_id: "1-340",
    code_name: "+1-340",
  },
  {
    code_id: "1-671",
    code_name: "+1-671",
  },
  {
    code_id: "1-670",
    code_name: "+1-670",
  },
  {
    code_id: "20",
    code_name: "+20",
  },
  {
    code_id: "211",
    code_name: "+211",
  },
  {
    code_id: "212",
    code_name: "+212",
  },
  {
    code_id: "213",
    code_name: "+213",
  },
  {
    code_id: "216",
    code_name: "+216",
  },
  {
    code_id: "218",
    code_name: "+218",
  },
  {
    code_id: "220",
    code_name: "+220",
  },
  {
    code_id: "221",
    code_name: "+221",
  },
  {
    code_id: "222",
    code_name: "+222",
  },
  {
    code_id: "223",
    code_name: "+223",
  },
  {
    code_id: "224",
    code_name: "+224",
  },
  {
    code_id: "225",
    code_name: "+225",
  },
  {
    code_id: "226",
    code_name: "+226",
  },
  {
    code_id: "227",
    code_name: "+227",
  },
  {
    code_id: "228",
    code_name: "+228",
  },
  {
    code_id: "229",
    code_name: "+229",
  },
  {
    code_id: "230",
    code_name: "+230",
  },
  {
    code_id: "231",
    code_name: "+231",
  },
  {
    code_id: "232",
    code_name: "+232",
  },
  {
    code_id: "233",
    code_name: "+233",
  },
  {
    code_id: "234",
    code_name: "+234",
  },
  {
    code_id: "235",
    code_name: "+235",
  },
  {
    code_id: "236",
    code_name: "+236",
  },
  {
    code_id: "237",
    code_name: "+237",
  },
  {
    code_id: "238",
    code_name: "+238",
  },
  {
    code_id: "239",
    code_name: "+239",
  },
  {
    code_id: "240",
    code_name: "+240",
  },
  {
    code_id: "241",
    code_name: "+241",
  },
  {
    code_id: "242",
    code_name: "+242",
  },
  {
    code_id: "243",
    code_name: "+243",
  },
  {
    code_id: "244",
    code_name: "+244",
  },
  {
    code_id: "245",
    code_name: "+245",
  },
  {
    code_id: "246",
    code_name: "+246",
  },
  {
    code_id: "247",
    code_name: "+247",
  },
  {
    code_id: "248",
    code_name: "+248",
  },
  {
    code_id: "249",
    code_name: "+249",
  },
  {
    code_id: "250",
    code_name: "+250",
  },
  {
    code_id: "251",
    code_name: "+251",
  },
  {
    code_id: "252",
    code_name: "+252",
  },
  {
    code_id: "253",
    code_name: "+253",
  },
  {
    code_id: "254",
    code_name: "+254",
  },
  {
    code_id: "255",
    code_name: "+255",
  },
  {
    code_id: "256",
    code_name: "+256",
  },
  {
    code_id: "257",
    code_name: "+257",
  },
  {
    code_id: "258",
    code_name: "+258",
  },
  {
    code_id: "259",
    code_name: "+259",
  },
  {
    code_id: "260",
    code_name: "+260",
  },
  {
    code_id: "261",
    code_name: "+261",
  },
  {
    code_id: "262",
    code_name: "+262",
  },
  {
    code_id: "263",
    code_name: "+263",
  },
  {
    code_id: "264",
    code_name: "+264",
  },
  {
    code_id: "265",
    code_name: "+265",
  },
  {
    code_id: "266",
    code_name: "+266",
  },
  {
    code_id: "267",
    code_name: "+267",
  },
  {
    code_id: "268",
    code_name: "+268",
  },
  {
    code_id: "269",
    code_name: "+269",
  },
  {
    code_id: "27",
    code_name: "+27",
  },
  {
    code_id: "290",
    code_name: "+290",
  },
  {
    code_id: "291",
    code_name: "+291",
  },
  {
    code_id: "297",
    code_name: "+297",
  },
  {
    code_id: "298",
    code_name: "+298",
  },
  {
    code_id: "299",
    code_name: "+299",
  },
  {
    code_id: "30",
    code_name: "+30",
  },
  {
    code_id: "31",
    code_name: "+31",
  },
  {
    code_id: "32",
    code_name: "+32",
  },
  {
    code_id: "33",
    code_name: "+33",
  },
  {
    code_id: "34",
    code_name: "+34",
  },
  {
    code_id: "350",
    code_name: "+350",
  },
  {
    code_id: "351",
    code_name: "+351",
  },
  {
    code_id: "352",
    code_name: "+352",
  },
  {
    code_id: "353",
    code_name: "+353",
  },
  {
    code_id: "354",
    code_name: "+354",
  },
  {
    code_id: "355",
    code_name: "+355",
  },
  {
    code_id: "356",
    code_name: "+356",
  },
  {
    code_id: "357",
    code_name: "+357",
  },
  {
    code_id: "358",
    code_name: "+358",
  },
  {
    code_id: "359",
    code_name: "+359",
  },
  {
    code_id: "36",
    code_name: "+36",
  },
  {
    code_id: "37",
    code_name: "+37",
  },
  {
    code_id: "370",
    code_name: "+370",
  },
  {
    code_id: "371",
    code_name: "+371",
  },
  {
    code_id: "372",
    code_name: "+372",
  },
  {
    code_id: "373",
    code_name: "+373",
  },
  {
    code_id: "374",
    code_name: "+374",
  },
  {
    code_id: "375",
    code_name: "+375",
  },
  {
    code_id: "376",
    code_name: "+376",
  },
  {
    code_id: "377",
    code_name: "+377",
  },
  {
    code_id: "378",
    code_name: "+378",
  },
  {
    code_id: "379",
    code_name: "+379",
  },
  {
    code_id: "38",
    code_name: "+38",
  },
  {
    code_id: "380",
    code_name: "+380",
  },
  {
    code_id: "381",
    code_name: "+381",
  },
  {
    code_id: "382",
    code_name: "+382",
  },
  {
    code_id: "385",
    code_name: "+385",
  },
  {
    code_id: "386",
    code_name: "+386",
  },
  {
    code_id: "387",
    code_name: "+387",
  },
  {
    code_id: "388",
    code_name: "+388",
  },
  {
    code_id: "389",
    code_name: "+389",
  },
  {
    code_id: "39",
    code_name: "+39",
  },
  {
    code_id: "40",
    code_name: "+40",
  },
  {
    code_id: "41",
    code_name: "+41",
  },
  {
    code_id: "42",
    code_name: "+42",
  },
  {
    code_id: "420",
    code_name: "+420",
  },
  {
    code_id: "421",
    code_name: "+421",
  },
  {
    code_id: "423",
    code_name: "+423",
  },
  {
    code_id: "43",
    code_name: "+43",
  },
  {
    code_id: "44",
    code_name: "+44",
  },
  {
    code_id: "45",
    code_name: "+45",
  },
  {
    code_id: "46",
    code_name: "+46",
  },
  {
    code_id: "47",
    code_name: "+47",
  },
  {
    code_id: "48",
    code_name: "+48",
  },
  {
    code_id: "49",
    code_name: "+49",
  },
  {
    code_id: "500",
    code_name: "+500",
  },
  {
    code_id: "501",
    code_name: "+501",
  },
  {
    code_id: "502",
    code_name: "+502",
  },
  {
    code_id: "503",
    code_name: "+503",
  },
  {
    code_id: "504",
    code_name: "+504",
  },
  {
    code_id: "505",
    code_name: "+505",
  },
  {
    code_id: "506",
    code_name: "+506",
  },
  {
    code_id: "507",
    code_name: "+507",
  },
  {
    code_id: "508",
    code_name: "+508",
  },
  {
    code_id: "509",
    code_name: "+509",
  },
  {
    code_id: "51",
    code_name: "+51",
  },
  {
    code_id: "52",
    code_name: "+52",
  },
  {
    code_id: "53",
    code_name: "+53",
  },
  {
    code_id: "54",
    code_name: "+54",
  },
  {
    code_id: "55",
    code_name: "+55",
  },
  {
    code_id: "56",
    code_name: "+56",
  },
  {
    code_id: "57",
    code_name: "+57",
  },
  {
    code_id: "58",
    code_name: "+58",
  },
  {
    code_id: "590",
    code_name: "+590",
  },
  {
    code_id: "591",
    code_name: "+591",
  },
  {
    code_id: "592",
    code_name: "+592",
  },
  {
    code_id: "593",
    code_name: "+593",
  },
  {
    code_id: "594",
    code_name: "+594",
  },
  {
    code_id: "595",
    code_name: "+595",
  },
  {
    code_id: "596",
    code_name: "+596",
  },
  {
    code_id: "597",
    code_name: "+597",
  },
  {
    code_id: "598",
    code_name: "+598",
  },
  {
    code_id: "599",
    code_name: "+599",
  },
  {
    code_id: "599-9",
    code_name: "+599-9",
  },
  {
    code_id: "60",
    code_name: "+60",
  },
  {
    code_id: "61",
    code_name: "+61",
  },
  {
    code_id: "62",
    code_name: "+62",
  },
  {
    code_id: "63",
    code_name: "+63",
  },
  {
    code_id: "64",
    code_name: "+64",
  },
  {
    code_id: "65",
    code_name: "+65",
  },
  {
    code_id: "66",
    code_name: "+66",
  },
  {
    code_id: "670",
    code_name: "+670",
  },
  {
    code_id: "672",
    code_name: "+672",
  },
  {
    code_id: "673",
    code_name: "+673",
  },
  {
    code_id: "674",
    code_name: "+674",
  },
  {
    code_id: "675",
    code_name: "+675",
  },
  {
    code_id: "676",
    code_name: "+676",
  },
  {
    code_id: "677",
    code_name: "+677",
  },
  {
    code_id: "678",
    code_name: "+678",
  },
  {
    code_id: "679",
    code_name: "+679",
  },
  {
    code_id: "680",
    code_name: "+680",
  },
  {
    code_id: "681",
    code_name: "+681",
  },
  {
    code_id: "682",
    code_name: "+682",
  },
  {
    code_id: "683",
    code_name: "+683",
  },
  {
    code_id: "685",
    code_name: "+685",
  },
  {
    code_id: "686",
    code_name: "+686",
  },
  {
    code_id: "687",
    code_name: "+687",
  },
  {
    code_id: "688",
    code_name: "+688",
  },
  {
    code_id: "689",
    code_name: "+689",
  },
  {
    code_id: "690",
    code_name: "+690",
  },
  {
    code_id: "691",
    code_name: "+691",
  },
  {
    code_id: "692",
    code_name: "+692",
  },
  {
    code_id: "7",
    code_name: "+7",
  },
  {
    code_id: "81",
    code_name: "+81",
  },
  {
    code_id: "82",
    code_name: "+82",
  },
  {
    code_id: "84",
    code_name: "+84",
  },
  {
    code_id: "850",
    code_name: "+850",
  },
  {
    code_id: "852",
    code_name: "+852",
  },
  {
    code_id: "853",
    code_name: "+853",
  },
  {
    code_id: "855",
    code_name: "+855",
  },
  {
    code_id: "856",
    code_name: "+856",
  },
  {
    code_id: "86",
    code_name: "+86",
  },
  {
    code_id: "870",
    code_name: "+870",
  },
  {
    code_id: "878",
    code_name: "+878",
  },
  {
    code_id: "880",
    code_name: "+880",
  },
  {
    code_id: "881",
    code_name: "+881",
  },
  {
    code_id: "882",
    code_name: "+882",
  },
  {
    code_id: "886",
    code_name: "+886",
  },
  {
    code_id: "90",
    code_name: "+90",
  },
  {
    code_id: "91",
    code_name: "+91",
  },
  {
    code_id: "92",
    code_name: "+92",
  },
  {
    code_id: "93",
    code_name: "+93",
  },
  {
    code_id: "94",
    code_name: "+94",
  },
  {
    code_id: "95",
    code_name: "+95",
  },
  {
    code_id: "960",
    code_name: "+960",
  },
  {
    code_id: "961",
    code_name: "+961",
  },
  {
    code_id: "962",
    code_name: "+962",
  },
  {
    code_id: "963",
    code_name: "+963",
  },
  {
    code_id: "964",
    code_name: "+964",
  },
  {
    code_id: "965",
    code_name: "+965",
  },
  {
    code_id: "966",
    code_name: "+966",
  },
  {
    code_id: "967",
    code_name: "+967",
  },
  {
    code_id: "968",
    code_name: "+968",
  },
  {
    code_id: "969",
    code_name: "+969",
  },
  {
    code_id: "970",
    code_name: "+970",
  },
  {
    code_id: "971",
    code_name: "+971",
  },
  {
    code_id: "972",
    code_name: "+972",
  },
  {
    code_id: "973",
    code_name: "+973",
  },
  {
    code_id: "974",
    code_name: "+974",
  },
  {
    code_id: "975",
    code_name: "+975",
  },
  {
    code_id: "976",
    code_name: "+976",
  },
  {
    code_id: "977",
    code_name: "+977",
  },
  {
    code_id: "979",
    code_name: "+979",
  },
  {
    code_id: "98",
    code_name: "+98",
  },
  {
    code_id: "991",
    code_name: "+991",
  },
  {
    code_id: "992",
    code_name: "+992",
  },
  {
    code_id: "993",
    code_name: "+993",
  },
  {
    code_id: "994",
    code_name: "+994",
  },
  {
    code_id: "995",
    code_name: "+995",
  },
  {
    code_id: "996",
    code_name: "+996",
  },
  {
    code_id: "998",
    code_name: "+998",
  },
]

export default areaCodeList
